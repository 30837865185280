<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Form Part'">
        <template v-slot:preview>
          <b-row>
            <b-col md="12">
              <b-form class="form" @submit.stop.prevent="onSubmit">
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="nama_part_label"
                  label="Nama part :"
                  label-for="nama_part"
                >
                  <b-form-input
                    id="nama_part"
                    v-model="$v.form.nama_part.$model"
                    type="text"
                    placeholder="Enter Text..."
                    :state="validateState('nama_part')"
                  ></b-form-input>

                  <b-form-invalid-feedback id="nama_part_feedback">
                    Nama Part Harus Terisi.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-button
                  variant="outline-dark"
                  :disabled="loading"
                  @click="$router.push('/master/part')"
                >
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <span v-else class="svg-icon svg-icon-sm svg-icon-dark">
                    <i class="flaticon2-left-arrow-1"></i>
                  </span>
                  Discard
                </b-button>

                <b-button class="ml-3" type="submit" variant="outline-success" :disabled="loading">
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <!-- <strong>Loading...</strong> -->
                  <span v-else class="svg-icon svg-icon-sm svg-icon-success">
                    <i class="flaticon2-check-mark"></i>
                  </span>
                  Submit
                </b-button>
              </b-form>
            </b-col>
          </b-row>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>
<style lang="scss">
@import '~vuetify/dist/vuetify.css';

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { mapState } from 'vuex'

import ApiService from '@/core/services/api.service'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],
  name: 'addpart',
  data() {
    return {
      form: {
        nama_part: null,
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 0,
      filter: null,
      filterOn: [],

      loading: false,
      id: 0,
    }
  },
  validations: {
    form: {
      nama_part: {
        required,
      },
    },
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Master', route: '/master/part' },
      { title: 'Create Part' },
    ])
  },
  created() {
    var self = this
    this.id = this.$route.params.id

    ApiService.get('/master/part/edit/' + self.id)
      .then(({ data }) => {
        if (data.status == 'ok') {
          self.form.nama_part = data.data.nama_part
        }
      })
      .catch(response => {
        console.log(response)
      })
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    onSubmit() {
      // event.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      var self = this
      self.loading = true
      const nama_part = self.$v.form.nama_part.$model

      ApiService.post('/master/part/update/' + self.id, {
        nama_part: nama_part,
      })
        .then(({ data }) => {
          if (data.status == 'ok') {
            self.makeToast('success', 'Data berhasil diubah')
            self.$router.push('/master/part')
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
    }),
  },
}
</script>
